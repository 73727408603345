@import "variables";
@import "buttons";
@import "fonts";
@import "layout";
@import "common";
@import "form";

body {
  margin: 0;
  padding: 0;
  font-family: 'AGP Light', sans-serif;
  font-size: 18px;
  color: $white-1;
  background-color: #0D1424;
  background-size: cover;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    width: 4px;
    border: none;
  }


  &::-webkit-scrollbar-thumb {
    background-color: #FFA500;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-image: url('../assets/images/scrollbar.png');
    background-repeat: repeat-y;
    background-size: contain;
    background-position: center;
  }
}

body > * {
  &::-webkit-scrollbar-track {
    background-image: url("../assets/images/scrollbar.png");
  }
}

.table th, .table td, button.copyTextBtn {
  color: #FFF;
}


a {
  color: $orange-1;

  word-wrap: break-word;
}

a:hover {
  color: $orange-2;
}