@import "variables";
@import "fonts";

.text-orange-1 {
  color: $orange-1,
}

.text-orange-2 {
  color: $orange-2,
}

.text-white-1 {
  color: $white-1,
}

.text-white-transparent-1 {
  color: $white-transparent-1,
}

.text-white-transparent-2 {
  color: $white-transparent-2,
}

.text-white-transparent-3 {
  color: $white-transparent-3,
}

.text-white-transparent-4 {
  color: $white-transparent-4,
}

.text-white-transparent-5 {
  color: $white-transparent-5,
}

.text-white-transparent-6 {
  color: $white-transparent-6,
}

.text-white-transparent-7 {
  color: $white-transparent-7,
}

.text-black-1 {
  color: $black-1,
}

.text-grey-1 {
  color: $gray-1,
}

.text-blue-1 {
  color: $blue-1,
}

.text-blue-2 {
  color: $blue-2,
}

.text-blue-3 {
  color: $blue-3,
}

.bgc-orange-1 {
  background-color: $orange-1,
}

.bgc-orange-2 {
  background-color: $orange-2,
}

.bgc-white-1 {
  background-color: $white-1,
}

.bgc-white-transparent-1 {
  background-color: $white-transparent-1,
}

.bgc-white-transparent-2 {
  background-color: $white-transparent-2,
}

.bgc-white-transparent-3 {
  background-color: $white-transparent-3,
}

.bgc-white-transparent-4 {
  background-color: $white-transparent-4,
}

.bgc-white-transparent-5 {
  background-color: $white-transparent-5,
}

.bgc-white-transparent-6 {
  background-color: $white-transparent-6,
}

.bgc-white-transparent-7 {
  background-color: $white-transparent-7,
}

.bgc-black-1 {
  background-color: $black-1,
}

.bgc-blue-1 {
  background-color: $blue-1,
}

.fz-1 {
  font-size: $font-size-text-1,
}

.fz-2 {
  font-size: $font-size-text-2,
}

.fz-3 {
  font-size: $font-size-text-3,
}

.fz-4 {
  font-size: $font-size-text-4,
}

.fz-5 {
  font-size: $font-size-text-5,
}

.fz-6 {
  font-size: $font-size-text-6,
}

.title-font-size-1 {
  font-size: $font-size-title-1,
}

.title-font-size-2 {
  font-size: $font-size-title-2,
}

.title-font-size-3 {
  font-size: $font-size-title-3,
}

.title-font-size-4 {
  font-size: $font-size-title-4,
}

.title-font-size-5 {
  font-size: $font-size-title-5,
}

.br-default {
  border-radius: $btn-border-radius;
}

.fw-title {
  font-family: "AGP Bold", sans-serif;
}

.fw-400 {
  font-family: "AGP Light", sans-serif;
}

.fw-500 {
  font-family: "AGP Regular", sans-serif;
}

.fw-600 {
  font-family: "AGP Medium", sans-serif;
}

.fw-700 {
  font-family: "AGP Bold", sans-serif;
}

.cp {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agp-font-style-4 {
  font-family: "AGP Light", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-text-4;
  line-height: $line-height-text-4;

  text-transform: initial;
}