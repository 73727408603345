@import "variables";
@import "common";

.header {
  background-color: $black-1;
  height: 80px;

  border-bottom: 1px solid $white-transparent-2;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 0;

  &-logo {
    width: 111px;
    height: 56px;
  }

  @media screen and (max-width: 1280px) {
    height: 115px;
  }
}

.welcome-screen-layout {
  padding-top: 48px;
  padding-bottom: 48px;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    height: calc(100vh - #{$header-height});
  }
}

.modal {

  @media screen and (max-width: 991px) {
    padding: 24px;
  }

  @media screen and (max-width: 576px) {
    padding: 0;
  }

  .modal-dialog {

    @media screen and (min-width: 576px) {
      max-width: 100%;
    }

    .modal-content {
      color: unset;
      background: rgba(40, 48, 64, 0.5);
      background-blend-mode: overlay;
      backdrop-filter: blur(15px);
      border: none;
      border-radius: 15px;
      padding: 80px 110px 100px 110px;
      margin: auto;
      width: auto;

      @media screen and (max-width: 576px) {
        padding: 60px 30px 40px 30px;
      }

      .modal-body {
      }
    }
  }
}


.toaster {
  position: fixed;
  top: 80px;
  padding: 0 !important;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: calc(100% - 24px);
  }

  @media screen and (max-width: 576px) {
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  .toast {
    padding: 40px !important;
    min-width: 518px;
    background: rgb(19, 31, 47) !important;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(200px);
    border-radius: 0 0 3px 3px;
    width: auto;
    font-family: "AGP Light", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;

    text-transform: none;

    @media screen and (max-width: 991px) {
      padding: 25px !important;
      font-size: 36px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      min-width: unset;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 20px !important;
      font-size: 24px;
    }

    .btn-close {
      position: absolute;
      background-image: url('../assets/images/icons/close.svg');
      right: 20px;
      top: 20px;

      @media screen and (max-width: 768px) {
        width: 0.5em;
        height: 0.5em;

        right: 8px;
        top: 8px;
      }

    }

    .toast-header {
      background: transparent;

      font-family: "AGP Light", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.02em;

      color: rgba(255, 255, 255, .5);

      margin-bottom: 10px;
      padding: 0 0 10px 0;

      border-bottom: 1px solid rgba(255, 255, 255, .5);

      display: block;
      word-wrap: break-word;

      @media screen and (max-width: 991px) {
        padding: 0 45px 12px 0;
      }

      @media screen and (max-width: 768px) {
        font-size: 20px;

        margin-bottom: 5px;
      }
    }

    .toast-body {
      padding: 0;
    }

    &.bg-danger {
      color: #e22525;
    }

    &.bg-success {
      @extend .text-orange-1;
    }
  }
}

.Toastify__toast-container {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  padding: 24px 24px 24px 100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0.45);

  @media screen and (max-width: 1200px) {
    padding: 24px 13px;
  }

  .Toastify__toast {
    background: none;
    color: unset;
    padding: 0;
    width: 326px;

    .Toastify__toast-body {
      padding: 0;
    }
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    width: 4px;
    border: none;
  }


  &::-webkit-scrollbar-thumb {
    background-color: #FFA500;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-image: url('../assets/images/scrollbar.png');
    background-repeat: repeat-y;
    background-size: contain;
    background-position: center;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.title {
  font-family: 'AGP Bold', sans-serif;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  &__xl {
    font-size: $font-size-title-5;
    color: $orange-1;
  }

  &__lg {
    font-size: $font-size-title-4;
    line-height: $line-height-title-4;
    color: $orange-1;
  }

  &__md {
    font-size: $font-size-title-3;
    line-height: 46px;
  }

  &__sm {
    font-size: $font-size-title-2;
  }

  &__xs {
    font-size: $font-size-title-1;
    color: $white-transparent-4;

    &--active {
      color: $white-1;
    }
  }
}

.subtitle {
  font-family: 'AGP Bold', sans-serif;
  letter-spacing: 0.02em;

  &__xl {
    font-size: $font-size-subtitle-5;
  }

  &__lg {
    font-size: $font-size-subtitle-4;
  }

  &__md {
    font-size: $font-size-subtitle-3;
  }

  &__sm {
    font-size: $font-size-subtitle-2;
  }

  &__xs {
    font-size: $font-size-subtitle-1;
    color: $white-transparent-4;

    &--active {
      color: $white-1;
    }
  }
}

.body-text {
  font-family: 'AGP Light', sans-serif;
  letter-spacing: 0.02em;

  &__xl {
    font-size: $font-size-text-5;
  }

  &__lg {
    font-size: $font-size-text-4;
  }

  &__md {
    font-size: $font-size-text-3;
  }

  &__sm {
    font-size: $font-size-text-2;
  }

  &__xs {
    font-size: $font-size-text-1;
    color: $white-transparent-4;

    &--active {
      color: $white-1;
    }
  }
}

.title-bold {
  font-family: 'AGP Bold', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-title-1;
  line-height: $line-height-title-1;
  letter-spacing: 0.02em;
}

.title-light {
  font-family: 'AGP Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-title-1;
  line-height: $line-height-title-1;
  letter-spacing: 0.02em;
}

.subtitle-bold {
  font-family: 'AGP Bold', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-subtitle-1;
  line-height: $line-height-subtitle-1;
  letter-spacing: 0.02em;
}

.subtitle-light {
  font-family: 'AGP Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-subtitle-1;
  line-height: $line-height-subtitle-1;
  letter-spacing: 0.02em;
}

.text-bold {
  font-family: 'AGP Bold', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-text-1;
  line-height: $line-height-text-1;
  letter-spacing: 0.02em;
}

.text-light {
  font-family: 'AGP Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-text-1;
  line-height: $line-height-text-1;
  letter-spacing: 0.02em;
}

.white-text-1 {
  color: $white-1 !important;
}

.white-text-2 {
  color: $white-2 !important;
}

.white-text-3 {
  color: $white-3 !important;
}

.orange-text-1 {
  color: $orange-1 !important;
}

.text-align-center {
  text-align: center;
}

.font-size-1 {
  font-size: $font-size-text-1 !important;
}

.table-actions-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.admin-page-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0;
  padding: 0;
}

.admin-page-title {
  display: inline-block;
  margin: 0 12px 0 0;
}