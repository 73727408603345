@import "variables";

.button {
  border: none;
  padding: 0;
  background: transparent;
  outline: none;

  &__wallet-select {
    background-color: $white-transparent-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @supports (backdrop-filter: blur(20px)) {
      backdrop-filter: blur(20px);
    }
    @supports not (backdrop-filter: blur(20px)) {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121D2E;
    }

    & > span {
      color: $white-1;
      font-size: $font-size-text-5;
      font-family: "AGP Medium", sans-serif;
      margin-top: 12px;
    }

    & > img {
      max-width: 40px;
      max-height: 40px;
    }

    &:focus:not(:disabled), &:active:not(:disabled) {
      background-color: $white-transparent-1;
    }

    &:hover:not(:disabled) {
      background-color: $white-transparent-2;
    }

    &:disabled {

      & > * {
        opacity: 0.2;
      }

      &:hover {
        @supports (backdrop-filter: blur(20px)) {
          backdrop-filter: blur(20px);
        }
        @supports not (backdrop-filter: blur(20px)) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121D2E;
        }
      }
    }
  }

  &__nft-stake, &__nft-claim {
    @extend .text-white-1;
    @extend .br-default;
    @extend .flex-center;
    border: none;
  }

  &__nft-stake {
    background-color: #314466;

    &:hover {
      &:not(:disabled) {
        @extend .bgc-orange-1;
      }
    }

    &:disabled, &:disabled:hover {
      background-color: #314466;
      @extend .text-white-transparent-6;
    }
  }

  &__nft-claim {
    background-color: $orange-1;
    color: $white-1;

    &:hover {
      &:not(:disabled) {
        @extend .bgc-orange-2;
        color: $white-1;
      }
    }

    &:disabled, &:disabled:hover {
      background-color: $orange-2;
      color: $white-transparent-6;
    }
  }

  &__nft-stake-all {
    @extend .button__nft-stake;

    &:not(:hover) {
      background-color: #24354B;
    }

    &:disabled, &:disabled:hover {
      background-color: #1E364D;
      color: #495664;
    }
  }

  &__nft-claim-all {
    @extend .button__nft-stake;

    background-color: $orange-1;
    color: $white-1;

    &:disabled, &:disabled:hover {
      background-color: $orange-1 !important;
      color: $white-transparent-6;
    }

    &:hover {
      &:not(:disabled) {
        @extend .bgc-orange-2;
      }
    }
  }

  &__nft-stake-modal {
    @extend .button__nft-stake;

    &:not(:hover) {
      background: linear-gradient(0deg, #44526F, #44526F), #3C475E !important;
    }

    &:disabled, &:disabled:hover {
      background: linear-gradient(0deg, #44526F, #44526F), #3C475E !important;
      color: #495664;
    }

    &:hover {
      background: #3C475E !important;
    }
  }

  &__wallet-dropdown {
    @extend .bgc-white-transparent-2;
    @extend .br-default;

    &:hover {
      @extend .bgc-white-transparent-3;
    }
  }

}
