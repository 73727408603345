.accordion-item,
.accordion-item div,
.accordion-item div button {
    background: #353B46 !important;
    border: 0;
    outline: 0;
}

.accordion-item div button::after {
    position: relative;
    border-radius: 4px;
    background: #FFFFFF !important;
}

.accordion-item .accordion-header .accordion-button:focus,
.accordion-item .accordion-header .accordion-button:not(.collapsed) {
    border-color: transparent;
    color: transparent;
    box-shadow: 0 0 0 0;
}

.accordion-item .accordion-header .accordion-button::before {
    content: "";
    position: absolute;
    z-index: 5;
    top: 25px;
    right: 18px;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 12px;
    height: 12px;
    border: 2px solid #353B46;
    border-top: none;
    border-right: none;
}