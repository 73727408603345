.react-select__menu {
    color: black;
}

.react-select-reward-passes {
    width: 100%;
    margin-right: 8px;
}

.rs-picker-menu {
    color: black !important;
    z-index: 100 !important;
}

.react-select {
    width: 100%;
}