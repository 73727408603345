$orange-1: #FFA500;
$orange-2: #D29002;

$white-1: #FFFFFF;
$white-2: #BDB8AE;
$white-3: rgba(255, 255, 255, 0.5);

$white-transparent-1: rgba(255, 255, 255, 0.05);
$white-transparent-2: rgba(255, 255, 255, 0.1);
$white-transparent-3: rgba(255, 255, 255, 0.15);
$white-transparent-4: rgba(255, 255, 255, 0.2);
$white-transparent-5: rgba(255, 255, 255, 0.3);
$white-transparent-6: rgba(255, 255, 255, 0.5) !important;
$white-transparent-7: rgba(255, 255, 255, 0.8);

$black-1: #1F2531;

$gray-1: #24354B;

$blue-1: #009BFD;
$blue-2: #1E3370;
$blue-3: #070E18;

$font-size-text-1: 20px;
$font-size-text-2: 22px;
$font-size-text-3: 24px;
$font-size-text-4: 26px;
$font-size-text-5: 28px;
$font-size-text-6: 30px;

$font-size-subtitle-1: 28px;
$font-size-subtitle-2: 30px;
$font-size-subtitle-3: 32px;
$font-size-subtitle-4: 36px;
$font-size-subtitle-5: 38px;

$font-size-title-1: 36px;
$font-size-title-2: 38px;
$font-size-title-3: 40px;
$font-size-title-4: 42px;
$font-size-title-5: 44px;

$line-height-text-1: 24px;
$line-height-subtitle-1: 32px;
$line-height-title-1: 40px;
$line-height-text-4: 19px;
$line-height-title-4: 45px;

$header-height: 80px;
$btn-border-radius: 3px;
