.my-modal .btn.btn-close {
    display: none;
}

.my-modal-body-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    padding: 30px 0;
}

.ReactModal__Overlay--after-open {
    opacity: 1;

    position: relative;
    z-index: 99;

    display: flex;
    align-items: center;

    max-width: 100%;
    min-height: calc(100% - 30px * 2);
    width: auto;

    background-color: rgba(0, 0, 0, 0.5) !important;

    overflow-y: auto;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    position: relative;
    z-index: 100;

    display: flex;
    flex-direction: column;

    width: auto;

    background: rgba(40, 48, 64, .5);
    background-blend-mode: overlay;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    border: none;
    border-radius: 15px;
    outline: 0;

    pointer-events: auto;

    padding: 80px 110px 100px;
    margin: auto;
}

@media (max-width: 950px) {
    .ReactModal__Content {
        padding: 60px 90px;
    }
}

@media (max-width: 520px) {
    .ReactModal__Content {
        padding: 30px 40px;

        width: 100%;
    }

    .ReactModal__Overlay {
        padding: 0;
    }
}

.ReactModal__Overlay::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.ReactModal__Overlay::-webkit-scrollbar-thumb {
    background-color: #FFA500;
    border-radius: 4px;
}

.ReactModal__Overlay::-webkit-scrollbar-track {
    background-image: url('../../assets/images/scrollbar.png');
    background-repeat: repeat-y;
    background-size: contain;
    background-position: center;
}