@font-face {
  font-family: 'BNT';
  src: url("../assets/fonts/BigNoodleTitling/bignoodletitlingrusbydaymarius.ttf") format("truetype"),
  url("../assets/fonts/BigNoodleTitling/bignoodletitlingrusbydaymarius.ttf");
}

@font-face {
  font-family: 'AGP Light';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_light.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_light.ttf");
}

@font-face {
  font-family: 'AGP Regular';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_regular.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_regular.ttf");
}

@font-face {
  font-family: 'AGP Medium';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_md.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_md.ttf");
}

@font-face {
  font-family: 'AGP Bold';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_bold.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_bold.ttf");
}


